import React, { useRef } from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { Flex, Box } from 'components/ReflexBox';
import { getSrc } from 'gatsby-plugin-image';

import Page from 'components/Page';
import CenterContent from 'components/CenterContent';
import { Header } from 'components/Blogpost';
import CardSlider from 'components/CardSlider';
import { H2, H3, Text } from 'components/Typo';
import { FeaturedImage } from 'components/Blogpost';

import { getCategoryColorName, getThemeColor } from 'utils/css-utils';
import { getMainCategory } from 'utils/project-utils';
import useSliderOffset from 'hooks/useSliderOffset';

import Mdx from './mdx-renderer/BlogMdx';

const getSlidesPerView = () => {
  const width = typeof window !== 'undefined' ? window.innerWidth : 0;

  if (width <= 768) {
    return 1;
  } else if (width <= 1000) {
    return 1.5;
  } else if (width <= 1400) {
    return 2;
  }

  return 3;
};

const slidesSpaceBetween = 16;

const RelatedPosts = styled(Box)`
  background: ${(p) => p.theme.colors.silverLighten60};

  .swiper-wrapper {
    height: 350px;
  }
`;

const contentMaxWidth = '620px';

const SlideItemImage = styled(Box)`
  background-image: url(${(p) =>
    getSrc(p.image.childImageSharp.gatsbyImageData)});
  background-size: cover;
  transition: filter 300ms;
  filter: opacity(0.6) drop-shadow(0 0 0 ${(p) => p.theme.colors[p.color]});
`;

const SlideItemReadMore = styled(Box)`
  color: ${(p) => p.theme.colors[p.color]};
  font-weight: 700;
  margin-top: auto;
`;

const SlideItemWrapper = styled(Flex)`
  height: 100%;
  border: 1px solid ${getThemeColor('silver')};
  color: ${(p) => p.theme.colors.text};

  &:hover {
    ${SlideItemImage} {
      filter: opacity(0.8) drop-shadow(0 0 0 ${(p) => p.theme.colors[p.color]});
    }
    ${SlideItemReadMore} {
      opacity: 0.6;
    }
  }
`;

const SlideItemHeadline = styled(H3)`
  margin: 0px;
`;

const SlideItem = ({
  slug,
  title,
  excerpt,
  category,
  categoryColor,
  featuredImage,
}) => {
  return (
    <Link to={slug}>
      <SlideItemWrapper color={categoryColor}>
        <Flex p={4} bg="white" flexDirection="column">
          <Text fontWeight={400} color={categoryColor}>
            {category}
          </Text>
          <SlideItemHeadline as="div">{title}</SlideItemHeadline>
          <Text my={3}>{excerpt}</Text>
          <SlideItemReadMore color={categoryColor}>Read more</SlideItemReadMore>
        </Flex>
        {featuredImage && (
          <SlideItemImage
            color={categoryColor}
            minWidth={['50px', '100px']}
            image={featuredImage}
          />
        )}
      </SlideItemWrapper>
    </Link>
  );
};

const BlogPostTemplate = ({ data }) => {
  const contentRef = useRef();
  const slidesPerView = getSlidesPerView();
  const sliderOffset = useSliderOffset({
    domNode: contentRef,
    slidesPerView,
    spaceBetween: slidesSpaceBetween,
  });

  const { content, related } = data;

  const {
    title,
    date,
    image,
    tags,
    author,
    featuredImage,
    showFeaturedImage,
    featuredImageAttribution,
  } = content.frontmatter;

  const categoryColor = getCategoryColorName(tags);

  const relatedItems = related.edges.map(({ node }) => ({
    ...node.fields,
    ...node.frontmatter,
    id: node.fields.slug,
    category: getMainCategory(node.frontmatter.tags),
    categoryColor,
    excerpt: node.frontmatter.excerpt || node.excerpt,
  }));

  const metaTags = {
    title: `${title} - webkid blog`,
    author: 'Webkid',
    twitterUsername: '@webk1d',
    description: content.excerpt,
    siteUrl: `https://webkid.io${content.fields.slug}`,
    robots: 'index,follow',
    isBlog: true,
  };

  if (featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
    metaTags.image =
      featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
  }

  return (
    <Page theme="light" metaTags={metaTags}>
      <CenterContent maxWidth={contentMaxWidth} ref={contentRef}>
        <Header
          title={title}
          date={date}
          image={image}
          tags={tags}
          author={author}
        />
      </CenterContent>
      {showFeaturedImage && (
        <FeaturedImage
          bgColor={categoryColor}
          image={featuredImage?.childImageSharp?.gatsbyImageData}
          attribution={featuredImageAttribution}
        />
      )}
      <Mdx contentMaxWidth={contentMaxWidth} content={content.body} />
      {relatedItems.length > 0 && (
        <RelatedPosts pt={3} pb={5} mt={[4, 5]}>
          <CenterContent maxWidth={contentMaxWidth}>
            <H2 as="div">Further Reading</H2>
          </CenterContent>
          {sliderOffset !== null && (
            <CardSlider
              slidesPerView={slidesPerView}
              slidesOffsetBefore={sliderOffset}
              spaceBetween={slidesSpaceBetween}
              centeredSlides={true}
              items={relatedItems}
              SlideItem={SlideItem}
            />
          )}
        </RelatedPosts>
      )}
    </Page>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tags: [String!]) {
    content: mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        slug
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1100)
          }
        }
        featuredImageAttribution
        showFeaturedImage
        author {
          id
          name
          lastname
          twitter
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 125, height: 125)
            }
          }
        }
      }
    }
    related: allMdx(
      filter: {
        fields: { slug: { ne: $slug, regex: "/blog/" } }
        frontmatter: { published: { eq: true }, tags: { in: $tags } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            excerpt
            tags
            published
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 600
                  transformOptions: { cropFocus: ATTENTION, grayscale: true }
                )
              }
            }
            showFeaturedImage
          }
        }
      }
    }
  }
`;
